import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import Book from './Book';
import './BookList.css';

function RelatedBooks({ slug, limit }) {
    const [isLoading, setIsLoading] = useState(false);
    const [books, setBooks] = useState([]);

  const loadBooks = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const response = await axios.get('https://upcomingbook.com/api/books', {
        params: {
            mode: 'related',
            slug: slug,
            limit: limit
        }
      });

      const data = response.data;

      setBooks(data.books);
    } catch (error) {
      console.error("Error loading books:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, slug, limit]);

  useEffect(() => {
    loadBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!books.length) return '';

  return (
    <>
      {
        isLoading && (<div class="loader mt-4"></div>)
      }
      <p className="mt-4 mb-3 text-violet">
        <strong>Related upcoming books</strong>
      </p>
      <Row className="books related-books">
          {books.map((book) => (
            <Col xl={6} key={book.id}>
                <Book
                    title={book.title}
                    authors={book.authors}
                    publishedDate={book.published_date}
                    slug={book.slug}
                    img_checked={typeof book.img_checked === 'undefined' ? true : book.img_checked}
                />
            </Col>
          ))}
      </Row>
    </>
  );
}

export default RelatedBooks;
