import { Link } from 'react-router-dom';

// Helper function to format category segments for the URL
export function formatPathSegment(segment) { 
    return segment.replace(/[^a-zA-Z0-9]+/g, '_').replace(/_+/g, '_').replace(/_$/, '');
}

// Function to render categories with links
export function renderCategoryLinks(categories, isBreadcrumbs = false) {
    return categories.map((category, index) => {
        // Split category into segments and build paths
        const subcategories = category.split(' / ');
        if (isBreadcrumbs) {
            subcategories.unshift('Home');
            subcategories.pop();
        }
        let fullPath = '';

        // Generate links for each subcategory in the current category
        const links = subcategories.map((subcategory, i) => {
            // Incrementally build the full path
            let href;
            if (subcategory === 'Home') href = '/';
            else {
                fullPath += (i === 0 || (i === 1 && isBreadcrumbs) ? '' : '_') + formatPathSegment(subcategory);
                href = `/category/${fullPath}`;
            }
            return (
                <Link key={`${index}-${i}`} to={href} className="ctg-link">
                    {subcategory}
                </Link>
            );
        });

        // Return the complete set of links for this category, separated by " > "
        return (
        <div key={index} className="ctg-line">
            {links.reduce((acc, link, i) => (i === 0 ? [link] : [...acc, <span className="mx-2" key={`chevron-${i}`}>/</span>, link]), [])}
        </div>
        );
    });
}; // renderCategoryLinks

// Function to render author as link
export function renderAuthorLink(author) {
    const authorSlug = formatPathSegment(author);
    return (
        <Link to={`/by/${authorSlug}`} className="author-link" state={{ author }}>
            {author}
        </Link>
    );
};
