import './BookSellers.css';

const BookSellers = ({ book, display, preOrderText, width }) => {

    const search_term = encodeURI(`${book.title} ${book.authors}`);

    return (
        <div className={`booksellers mt-3 ${display}`} style={{width: width + "px"}}>
            <div className="fw-bold text-violet mb-2">{preOrderText}</div>
            <a href={`https://www.amazon.com/s?k=${search_term}&tag=upcomingbook-20`} target="_blank" rel="noreferrer">
                Amazon
            </a>
            <a href={`https://www.barnesandnoble.com/s/${search_term}`} target="_blank" rel="noreferrer">
                Barnes &amp; Noble
            </a>
            <a href={`https://www.booksamillion.com/search2?query=${search_term}`} target="_blank" rel="noreferrer">
                Books-A-Million
            </a>
            <a href={`https://www.powells.com/searchresults?keyword=${search_term}`} target="_blank" rel="noreferrer">
                Powell's
            </a>
            <a href={`https://www.abebooks.com/servlet/SearchResults?kn=${search_term}`} target="_blank" rel="noreferrer">
                AbeBooks
            </a>
        </div>
    );
};

export default BookSellers;
