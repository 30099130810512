import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { renderAuthorLink, renderCategoryLinks } from '../utils/renderLinks';
import AdminBook from './AdminBook';
import BookSellers from './BookSellers';
import RelatedBooks from './RelatedBooks';
import './BookPage.css';

const BookPage = () => {
  const [book, setBook] = useState(null);
  const [imgSizes, setImgSizes] = useState([]);
  
  const { slug } = useParams();
  const navigate = useNavigate();

  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(false);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const descriptionRef = useRef(null);

  const handleDescriptionToggle = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };


  useEffect(() => {
    // Check if slug matches the pattern <anything>-<id>
    const match = slug.match(/^(.*)-(\d+)$/);
    if (match) {
      const id = match[2]; // Extract the ID

      // Fetch the correct slug based on the ID
      const fetchCorrectSlug = async () => {
        try {
          const response = await fetch(`https://upcomingbook.com/api/book-slug/${id}`);
          if (response.ok) {
            const { slug: correctSlug } = await response.json();
            // Redirect to the correct slug if it doesn’t match
            if (correctSlug !== slug) {
              navigate(`/book/${correctSlug}`, { replace: true });
            }
          } else {
            console.error("Book not found");
          }
        } catch (error) {
          console.error("Error fetching slug:", error);
        }
      };

      fetchCorrectSlug();
    } // if (match)

    fetch(`https://upcomingbook.com/api/book?slug=${slug}`)
      .then(response => response.json())
      .then(data => {
        setBook(data.book);
        setImgSizes(data.images);
      })
      .catch(error => console.error('Error fetching book:', error));

    // Check if description content overflows the set max-height
    //setIsDescriptionOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
  }, [slug, navigate]); // useEffect

  useLayoutEffect(() => {
    if (descriptionRef.current) {
      // Use setTimeout to delay the check until after the component is fully rendered
      setTimeout(() => {
        // Check if description content overflows the set max-height
        setIsDescriptionOverflowing(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
      }, 0);
    }
  });

  if (!book) {
    return "";
  }

  const formattedDate = (() => {
    const today = new Date();
    const published = new Date(book.published_date);
  
    // Clear the time part for accurate day comparison
    today.setHours(0, 0, 0, 0);
    published.setHours(0, 0, 0, 0);
  
    // Calculate the difference in days
    const timeDifference = (published - today) / (1000 * 60 * 60 * 24);
  
    if (timeDifference === 0) {
      return <span className="text-violet">Today</span>;
    } else if (timeDifference === 1) {
      return <span className="text-violet">Tomorrow</span>;
    } else {
      return published.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    }
  })(); // formattedDate

  // YYYY-MM-DD
  const published = new Date(book.published_date);
  const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;

  // Authors
  const authors = book.authors.split(', ').map((author, index) => (
    <span key={author}>
      {index > 0 && ', '}
      {renderAuthorLink(author)}
    </span>
  ));
  
  // Categories
  let categories = [];
  if (book.info.categories && book.info.categories.length) {
    book.info.categories.forEach(category => {
      // Remove ' / General' from the end of each category, if present
      const cleanedCategory = category.replace(/ \/ General$/, '');

      // Check if the cleanedCategory is already covered by any existing category in 'categories'
      if (!categories.some(existing => existing !== cleanedCategory && existing.startsWith(cleanedCategory))) {
        categories.push(cleanedCategory);
      }
    });
  }

  // ISBNs
  let isbns = '';
  if (book.isbn_list) {
    isbns = book.isbn_list.split(', ').map((isbn) => (
      <div className="isbn">{isbn}</div>
    ))
  }

  const leftColWidth = imgSizes.includes('sm') && book.img_checked ? 300 : 128;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  published.setHours(0, 0, 0, 0);
  const preOrderText = today < published ? 'Pre-order' : 'Order';

  return (
    <>
      <Helmet>
          <title>{book.title} by {book.authors} - Upcoming Book</title>
      </Helmet>
      <div className="d-sm-flex mt-4 gap-5 book-page">
        <div className="left">
          { imgSizes.includes('sm') && book.img_checked ?
            <img src={`https://www.upcomingbook.com/images/books/md/${book.slug}.jpg`} className="d-block mx-auto book-image-md"
              width="300" alt={book.title}
            />
            :
            <img src={`https://www.upcomingbook.com/images/books/tmb/${book.slug}.jpg`} className="d-block mx-auto book-image"
              width="128" alt={book.title}
            />
          }

          <BookSellers book={book} display="d-none d-sm-block" preOrderText={preOrderText} width={leftColWidth} />
        </div>
        <div className="book-info">
          <div className="book-header mt-4 mt-sm-0 mb-4">
            <i className="bi bi-square-fill float-end"></i>
            <h1>{book.title}</h1>
            {book.info.subtitle && (
              <h3 className="text-secondary">{book.info.subtitle}</h3>
            )}
          </div>

          <p>by {authors}</p>

          <p>
            <i className="bi bi-calendar-event me-2"></i>
            <time dateTime={publishedYmd}>{formattedDate}</time>
            {book.info.publisher && (
              <>
                <i className="bi bi-dot mx-2 text-muted"></i>
                <span>{book.info.publisher}</span>
              </>
            )}
          </p>

          <article 
            className="book-description" 
            ref={descriptionRef}
            style={{ 
              maxHeight: isDescriptionExpanded ? '1000px' : '130px',
              borderBottomWidth: isDescriptionOverflowing || isDescriptionExpanded ? '1px' : '0'
            }}
            dangerouslySetInnerHTML={{ __html: book.description }} 
          />
          {(isDescriptionOverflowing || isDescriptionExpanded) && (
            <button className="description-toggle" onClick={handleDescriptionToggle}>
              {isDescriptionExpanded ? 'less' : 'more'}
            </button>
          )}
          <div className="mt-3"></div>

          {Number(book.info.pageCount) > 0 && (
            <p>
              <i className="bi bi-file-earmark d-inline-block flip-vertical me-2"></i>
              {Number(book.info.pageCount).toLocaleString()} pages
            </p>
          )}

          <BookSellers book={book} display="d-block d-sm-none w-100" preOrderText={preOrderText} width={leftColWidth} />

          {categories && (
            <section className="book-categories mt-3">
              {renderCategoryLinks(categories)}
            </section>
          )}

          {isbns && (
            <div className="my-3">ISBN {isbns}</div>
          )}

          {/* TODO: About author */}

          {categories && <RelatedBooks slug={book.slug} limit="20" />}

        </div> {/* .book-info */}


      </div> {/* .book-page */}
      {process.env.NODE_ENV === 'development' && (
        <AdminBook book={book} />
      )}
    </>
  );
};

export default BookPage;
