import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, FormControl, Image } from 'react-bootstrap';
import LoginModal from './LoginModal';
import UserModal from './UserModal';
import './MyNavbar.css';

const MyNavbar = ({ onSearch, isLoggedIn, handleLoginSuccess, userInfo, onLogout }) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault(); // Prevent form submission on Enter
      }
      // Navigate to '/' if we're not on it
      if (location.pathname !== '/') {
          navigate('/');
      }
    };

    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);

    const handleShowLoginModal = () => setShowLoginModal(true);
    const handleCloseLoginModal = () => setShowLoginModal(false);
    const handleShowUserModal = () => setShowUserModal(true);
    const handleCloseUserModal = () => setShowUserModal(false);
    const handleLogout = () => {
        setShowUserModal(false);
        onLogout();
    }


    // Temporarily hide login button on prod
    let loginBtnClass = "order-2 pe-0 float-end";
    if (process.env.NODE_ENV !== 'development') loginBtnClass += " d-none";

    return (
        <>
            <div className="d-sm-flex align-items-center mt-3 gap-3 top-bar">
                <Link to="/">
                    <img src="/images/upcoming_book.svg" width="200" height="auto" alt="Upcoming Book" />
                </Link>
                {isLoggedIn ? (
                    userInfo.picture.length ? (
                        <Image
                            src={userInfo.picture}
                            className={`user-image ${loginBtnClass}`}
                            id="userBtn"
                            title={userInfo.email}
                            alt={userInfo.email}
                            roundedCircle
                            onClick={handleShowUserModal}
                        />
                    ) : (
                        <Button
                            variant="link"
                            id="userBtn"
                            className={loginBtnClass}
                            onClick={handleShowUserModal}
                        >
                            <i className="bi bi-person-fill text-black"></i>
                        </Button>
                    )
                ) : (
                    <Button
                        variant="link"
                        id="userBtn"
                        className={loginBtnClass}
                        onClick={handleShowLoginModal}
                    >
                        <strong className="text-violet">Sign in</strong>
                    </Button>
                )}
                <Form className="search-form flex-grow-1 mt-3 mt-sm-0" onKeyDown={handleKeyPress}>
                    <FormControl
                        type="search"
                        placeholder="Search"
                        className="mr-2 search-input"
                        aria-label="Search"
                        onChange={onSearch}
                    />
                </Form>
            </div>
            
            <LoginModal show={showLoginModal} onHide={handleCloseLoginModal} onLoginSuccess={handleLoginSuccess} />

            <UserModal show={showUserModal} onHide={handleCloseUserModal} user={userInfo} onLogout={handleLogout} />
        </>
    );
};

export default MyNavbar;