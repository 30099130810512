import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useGoogleLogin } from '@react-oauth/google';
/*import {
    LoginSocialGoogle,
    LoginSocialAmazon,
    LoginSocialFacebook,
    LoginSocialInstagram,
    LoginSocialLinkedin,
    LoginSocialMicrosoft,
    LoginSocialPinterest,
    LoginSocialTwitter,
    LoginSocialApple,
  } from 'reactjs-social-login';*/
  import './LoginModal.css';

const LoginModal = ({ show, onHide, onLoginSuccess, sendAccessCode }) => {
    const [email, setEmail] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [error, setError] = useState('');

    const googleLogin = useGoogleLogin({
        onSuccess: (response) => {
            onLoginSuccess(response);
            onHide(); // Close the modal after successful login
        },
        onError: (error) => {
          console.error("Login Failed:", error);
        },
    });
    
    //const [provider, setProvider] = useState('');
    //const [profile, setProfile] = useState('');
  
    /*const onLoginStart = useCallback(() => {
      alert('login start');
    }, []);
  
    const onLogoutSuccess = useCallback(() => {
      //setProfile(null);
      //setProvider('');
      alert('logout success');
    }, []);
  
    const onLogout = useCallback(() => {}, []);*/
  
    const handleSendAccessCode = async (e) => {
        e.preventDefault();
        if (!email) {
            setError('Please enter your email.');
            return;
        }

        try {
            await sendAccessCode(email); // Call the function to send the access code
            setIsCodeSent(true);
            setError('');
        } catch (err) {
            console.error("Error sending access code:", err);
            setError('Failed to send access code. Please try again.');
        }
    };

    const handleVerifyAccessCode = (e) => {
        e.preventDefault();
        if (accessCode === '123456') { // Replace this with actual verification logic
            onLoginSuccess({ email });
            setError('');
            onHide();
        } else {
            setError('Invalid access code. Please try again.');
        }
    };

    return (
        <Modal className="login-modal" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Sign in</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Use your favorite social network</p>
                <div className="login-buttons">
                    <Button variant="primary" onClick={() => googleLogin()}>
                        <i className="bi bi-google me-2"></i> Google
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Body style={{borderBottom: "var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color)"}}>
                <div className="email-login">
                    <p>Email access code</p>
                    <Form onSubmit={isCodeSent ? handleVerifyAccessCode : handleSendAccessCode}>
                        {!isCodeSent ? (
                            <div className="d-flex gap-2">
                                <Form.Control
                                    type="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Button variant="primary" type="submit" className="text-nowrap">
                                    <i class="bi bi-envelope-at me-2"></i> Send code
                                </Button>
                            </div>
                        ) : (
                            <>
                                <Form.Group controlId="formAccessCode" className="mb-3">
                                    <Form.Label>Access Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter the access code"
                                        value={accessCode}
                                        onChange={(e) => setAccessCode(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    Verify Code
                                </Button>
                            </>
                        )}
                    </Form>
                    {error && <div className="alert alert-danger">{error}</div>}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default LoginModal;