import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const UserModal = ({ show, onHide, user, onLogout }) => {
  
    return (
        <Modal className="login-modal" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Preferences</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button variant="outline-danger" onClick={onLogout}>Sign out</Button>
            </Modal.Body>
        </Modal>
    );
};

export default UserModal;