import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import AdminRow from './AdminRow.js';
import Book from './Book';
import './BookList.css';
import { renderCategoryLinks } from '../utils/renderLinks.js';

function BookList({ query, limit }) {
  const [isLoading, setIsLoading] = useState(false);
  const [books, setBooks] = useState([]);
  const [title, setTitle] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState('');
  const [filterStates, setFilterStates] = useState({});
  
  const { author_slug, category_slug, date } = useParams();

  const handleFilterChange = (newFilterStates) => {
    setFilterStates(newFilterStates);
  };

  // Load books function
  const loadBooks = useCallback(async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      let params = {
        author_slug: author_slug,
        category_slug: category_slug,
        date: date,
        limit: limit
      };
      if (process.env.NODE_ENV === 'development') {
        params.admin_key = process.env.REACT_APP_ADMIN_KEY;
      }
      const response = await axios.get('https://upcomingbook.com/api/books', {
        params: params
      });

      const data = response.data;

      setBooks(data.books);
      if (data.title && data.title.length > 0) setTitle(data.title);
      else if (data.category && data.category.length > 0) {
        setTitle(data.category.split(' / ').pop());
        setBreadcrumbs(renderCategoryLinks([data.category], true));
      }
      else {
        setTitle('');
        setBreadcrumbs('');
      }
    } catch (error) {
      console.error("Error loading books:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, author_slug, category_slug, date, limit]);

  const filteredBooks = books.filter((book) => {
    const matchesQuery =
        !query ||
        book.title.toLowerCase().includes(query.toLowerCase()) ||
        book.authors.toLowerCase().includes(query.toLowerCase());

    // Apply additional filtering logic based on filterStates
    const passesFilters = Object.entries(filterStates).every(([filterKey, isActive]) => {
        if (process.env.NODE_ENV !== 'development') return true;
        if (!isActive) return true; // Ignore inactive filters
        if (filterKey === 'img_not_checked') return !book.img_checked;
        return book[filterKey];
    });

    return matchesQuery && passesFilters;
});

  useEffect(() => {
    loadBooks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [author_slug, category_slug, date]);

  return (
    <>
      <Helmet>
        <title>
          {title ? `${title} - ` : ''}
          Upcoming Book Releases
        </title>
      </Helmet>

      {
        isLoading && (<div class="loader mt-4"></div>)
      }
      {process.env.NODE_ENV === 'development' && (
        <AdminRow handleChange={handleFilterChange} />
      )}

      { !query && !author_slug && !category_slug && !date && (
        <section className="top-banner">
          <div>
            Discover your next favorite read with our comprehensive list of <strong className="text-violet text-nowrap">upcoming book releases</strong>
            <div className="d-none d-md-inline">.</div>
          </div>
          <div className="d-none d-lg-block">Stay informed and never miss out on the most anticipated titles across all genres.</div>
          <div className="d-none d-md-block">Explore, plan, and add excitement to your reading journey!</div>
        </section>
      )}
      <div className="category-title">
        {title && (<h1 className="booklist-title">{title}</h1>)}
        {breadcrumbs && (<div className="breadcrumbs">{breadcrumbs}</div>)}
      </div>

      <Row className="books my-3">
          {filteredBooks.map((book) => (
            <Col xs md={6} lg={4} xl={3} key={book.id}>
                <Book
                    title={book.title}
                    authors={book.authors}
                    publishedDate={book.published_date}
                    slug={book.slug}
                    img_checked={typeof book.img_checked === 'undefined' ? true : book.img_checked}
                />
            </Col>
          ))}
      </Row>
    </>
  );
}

export default BookList;
