import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Route, Routes, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google';
import BookList from './components/BookList';
import BookPage from './components/BookPage';
import MyNavbar from './components/MyNavbar';
import './App.css';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [query, setQuery] = useState('');

  const handleLoginSuccess = async (response) => {
    if (response.access_token) {
      try {
        // Fetch user info from Google
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        });
  
        const userInfo = await userInfoResponse.json();
        //console.log("Fetched User Info:", userInfo);
  
        setUserInfo(userInfo);
        setIsLoggedIn(true); 
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        localStorage.setItem("isLoggedIn", true);

        // Send user info to the backend to save in the database
        const saveUserResponse = await fetch('https://upcomingbook.com/api/user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: userInfo.email,
            name: userInfo.name,
            photo_url: userInfo.picture || ''
          }),
        });

        const savedUser = await saveUserResponse.json();
        console.log("User saved:", savedUser); // Log the saved user info

      } catch (error) {
        console.error("Error fetching user info:", error);
        setIsLoggedIn(false);
      }
    } else {
      console.error("No access_token found in response.");
      setIsLoggedIn(false);
    }
  };

  const handleSearch = (event) => {
    const searchQuery = event.target.value;
    setQuery(searchQuery);
  };

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    const storedUserInfo = localStorage.getItem("userInfo");
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

    if (storedIsLoggedIn && storedUserInfo) {
        setUserInfo(JSON.parse(storedUserInfo));
        setIsLoggedIn(true);
    }

    // Event listener for changes in localStorage across tabs/windows
    const handleStorageChange = (event) => {
      if (event.key === "isLoggedIn" || event.key === "userInfo") {
        const updatedIsLoggedIn = localStorage.getItem("isLoggedIn");
        const updatedUserInfo = localStorage.getItem("userInfo");
    
        if (updatedIsLoggedIn && updatedUserInfo) {
          setUserInfo(JSON.parse(updatedUserInfo));
          setIsLoggedIn(true);
        } else {
          setUserInfo(null);
          setIsLoggedIn(false);
        }
      }
    };
    
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [location]); // useEffect

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUserInfo(null);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("isLoggedIn");
  };
 
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <HelmetProvider>
          <MyNavbar query={query} 
                    onSearch={handleSearch} 
                    isLoggedIn={isLoggedIn} 
                    handleLoginSuccess={handleLoginSuccess} 
                    onLogout={handleLogout}
                    userInfo={userInfo} />
          <Routes>
            <Route path="/" element={<BookList query={query} />} />
            <Route path="/category/:category_slug" element={<BookList />} />
            <Route path="/by/:author_slug" element={<BookList />} />
            <Route path="/date/:date" element={<BookList />} />
            <Route path="/book/:slug" element={<BookPage />} />
          </Routes>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
