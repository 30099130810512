import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Book.css';

const Book = ({ title, authors, publishedDate, slug, img_checked }) => {

  const formattedDate = (() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayYmd = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
    const tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    tomorrow.setDate(tomorrow.getDate() + 1); 
    const tomorrowYmd = `${tomorrow.getFullYear()}-${String(tomorrow.getMonth() + 1).padStart(2, '0')}-${String(tomorrow.getDate()).padStart(2, '0')}`;
    const published = new Date(publishedDate);
    const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;
    
    if (publishedYmd === todayYmd) {
      return <span className="text-violet">Today</span>;
    } else if (publishedYmd === tomorrowYmd) {
      return <span className="text-violet">Tomorrow</span>;
    } else {
      return published.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      });
    }
  })(); // formattedDate

  // YYYY-MM-DD
  const published = new Date(publishedDate);
  const publishedYmd = `${published.getFullYear()}-${String(published.getMonth() + 1).padStart(2, '0')}-${String(published.getDate()).padStart(2, '0')}`;

  const redBorder = img_checked ? '' : 'attention';

  return (
    <Card className={`mb-3 ${redBorder}`}>
      <Link to={`/book/${slug}`} target="_blank" className="book-link">
        <div className="position-relative">
          <img
            src={`https://www.upcomingbook.com/images/books/tmb/${slug}.jpg`}
            alt={title}
            width="128"
            height="183"
          />
        </div>
      </Link>
      <Card.Body className="py-0">
        <div className="d-flex justify-content-between">
          <Card.Text className="date">
            <time dateTime={publishedYmd}>{formattedDate}</time>
          </Card.Text>

          {process.env.NODE_ENV === 'development' && (
            <Dropdown className="dev-menu">
              <Dropdown.Toggle>
                <i class="bi bi-three-dots-vertical"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={`https://www.google.ru/books/edition/_/`} 
                  target="_blank" className="text-muted" rel="noreferrer">Google Books</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
        <Link to={`/book/${slug}`} className="book-link" target="_blank">
          <Card.Title className="mb-1">
            {title}
          </Card.Title>
          <Card.Text className="authors">
              {authors.split(',').slice(0, 3).map((author, index) => (
                <div key={index}>
                  {author.trim()}
                </div>
              ))}
          </Card.Text>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default Book;
